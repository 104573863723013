export class PlotlyHelper {
  #raw_data = null;
  #raw_downtimes = null;
  #raw_trips = null;
  #trip_mode = true;
  #locale = "es";
  #downtime_mode = false;
  #allEmpty = false;
  #moderbar_buttons = [];
  #show_downtime_lines = false;

  #buttons = [];
  #data_range = {
    xmin: null,
    xmax: null,
    ymin: null,
    ymax: null,
  };
  #margin_decimal = {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  };
  //layout attributes
  #layout_general = {
    title: "Sensor",
    symbol: "",
    objLayout: {},
    autosize: true,
    xaxis: {},
    yaxis: {},
  };

  #alternating_background = {
    isNeeded: false,
    interval: null,
    color: null,
    opacity: null,
  };

  //plotData attributes

  #alternating_traces_colors = [["4AD66D", "25A244"]];

  #getPlotdata_general = () => {
    return {
      mode: "lines",
      type: "scatter",
      hovertemplate: `<b>%{y:.2f}</b>${
        this.#layout_general.symbol
      }<br><b>Date</b>: %{x|%a %x %H:%M:%S}<br><extra></extra>`,
      hoverlabel: {
        bgcolor: "2DC653",
        font: {
          color: "white",
        },
      },
      hoverinfo: "rgb(0, 0, 0)",
      line: {
        shape: "spline",
        smoothing: 0.1,
      },
      marker: { size: 3 },
    };
  };
  #user_plotdata_obj = {};

  constructor(rawData = [], downtimes = []) {
    this.#initialSetup(rawData);
    this.#raw_downtimes = downtimes;
  }

  /**
   * To add base data to the layout
   * @param {*} title Main Title of the plot
   * @param {*} [symbol=null] Symbol of y-axis data
   * @param {*} [objLayout={}] Extra layout parameters for plotly
   * @return {*} itself
   * @memberof PlotlyHelper
   */
  historicalBaseLayout(title, symbol = null, objLayout = {}) {
    this.#layout_general.title = title;
    this.#layout_general.symbol = symbol ?? "";
    this.#layout_general.objLayout = objLayout;
    return this;
  }
  /**
   * To add margins to the plot
   *  Vertical and horizontal take precendence over the indivuduals ones
   * @param {number} [top=0]
   * @param {number} [bottom=0]
   * @param {number} [left=0]
   * @param {number} [right=0]
   * @param {*} [horizontal=null]
   * @param {*} [vertical=null]
   * @return {*}
   * @memberof PlotlyHelper
   */
  changeMargin(
    top = 0,
    bottom = 0,
    left = 0,
    right = 0,
    horizontal = null,
    vertical = null
  ) {
    this.#margin_decimal = {
      top: vertical ?? top,
      bottom: vertical ?? bottom,
      left: horizontal ?? left,
      right: horizontal ?? right,
    };
    return this;
  }
  /**
   * To add extra info to axis
   *
   * @param {*} [xaxis={}]
   * @param {*} [yaxis={}]
   * @return {*}
   * @memberof PlotlyHelper
   */
  axisConfig(xaxis = {}, yaxis = {}) {
    this.#layout_general.xaxis = {
      ...this.#layout_general.xaxis,
      ...xaxis,
    };
    this.#layout_general.yaxis = {
      ...this.#layout_general.yaxis,
      ...yaxis,
    };
    return this;
  }

  /**
   * To be merge to layout
   * @param {*} obj
   * @return {*}
   * @memberof PlotlyHelper
   */
  mergeLayoutObject(obj) {
    this.#layout_general.objLayout = obj;
    return this;
  }

  /**
   * Add a alternating background to the plot
   *
   * @param {number} [interval=12]
   * @param {string} [color="#ccc"]
   * @param {number} [opacity=0.2]
   * @return {*}
   * @memberof PlotlyHelper
   */
  addAlternatingBackground(interval = 12, color = "#ccc", opacity = 0.2) {
    this.#alternating_background.isNeeded = true;
    this.#alternating_background.interval = interval;
    this.#alternating_background.color = color;
    this.#alternating_background.opacity = opacity;
    return this;
  }
  /**
   * remove alternating background
   *
   * @return {*}
   * @memberof PlotlyHelper
   */
  removeAlternatingBackground() {
    this.#alternating_background.isNeeded = false;
    return this;
  }
  #createAlternatingBackground = () => {
    const datesBetween = getDatesBetween(
      this.#alternating_background.interval,
      this.#data_range.xmin,
      this.#data_range.xmax
    );
    const YDELTA = this.#data_range.ymax - this.#data_range.ymin;
    const ymax = this.#data_range.ymax + YDELTA * this.#margin_decimal.top;
    const ymin = this.#data_range.ymin - YDELTA * this.#margin_decimal.bottom;
    return createAlternatingBackground(
      datesBetween,
      ymin,
      ymax,
      this.#alternating_background.color,
      this.#alternating_background.opacity
    );
  };

  #createAxis = (text, tickformat) => {
    let axis = {
      automargin: true,
      autorange: true,
      tickangle: 0,
      title: {
        text,
        standoff: 20,
      },
      visible: true,
    };
    axis.tickformat = tickformat;
    return axis;
  };

  #initialSetup = (rawData) => {
    this.#raw_data = rawData;
    //revisar si todo los slots del mismo grafico están vacios
    if (rawData.length > 0) {
      this.#allEmpty = this.#raw_data.every((slot) => slot.showNoData);
    } else {
      this.#allEmpty = true;
    }

    //calcular data_range
    if (!this.#allEmpty) {
      this.range = this.#raw_data;
    }
  };

  changeData = (rawData) => {
    this.#initialSetup(rawData);
    return this;
  };
  //--- setters ---
  set range(data) {
    const MINIMUN_DATE = 0;
    const MAXIMUN_DATE = 8640000000000000;
    const MINIMUN_VALUE = -50000000;
    const MAXIMUN_VALUE = 5000000;
    //Assumes data is a orderer time series
    const newestDate = data.reduce((acc, curr) => {
      const slotDate = new Date(curr.x[0]);
      return slotDate < acc ? slotDate : acc;
    }, new Date(MAXIMUN_DATE));

    const oldestDate = data.reduce((acc, curr) => {
      const slotDate = new Date(curr.x[curr.x.length - 1]);
      return slotDate > acc ? slotDate : acc;
    }, new Date(MINIMUN_DATE));

    const miny = data.reduce((acc, curr) => {
      const slotMin = Math.min(...curr.y);
      return slotMin < acc ? slotMin : acc;
    }, MAXIMUN_VALUE);

    const maxy = data.reduce((acc, curr) => {
      const slotMin = Math.max(...curr.y);
      return slotMin > acc ? slotMin : acc;
    }, MINIMUN_VALUE);

    this.#data_range = {
      xmin: newestDate,
      xmax: oldestDate,
      ymin: miny,
      ymax: maxy,
    };
  }

  //--- getters ---

  get range() {
    return this.#data_range;
  }
  get layout() {
    //está todo vacio? return layout for no data
    if (this.#allEmpty) {
      return EMPTY_LAYOUT;
    }
    //calcular layout por partes
    const xaxisTitle = "Date";
    const yaxisTitle = `${this.#layout_general.title} (${
      this.#layout_general.symbol
    })`;
    const xaxisTickFormat = "%a %H:%M\n%x";
    const yaxisTickFormat = ".2f";
    const XAXIS = this.#createAxis(xaxisTitle, xaxisTickFormat);
    const YAXIS = this.#createAxis(yaxisTitle, yaxisTickFormat);

    const baseLayout = {
      title: {
        text: this.#layout_general.title,
      },
      symbol: this.#layout_general.symbol,
      autosize: true,
      xaxis: {
        ...XAXIS,
        ...this.#layout_general.xaxis,
      },
      yaxis: {
        ...YAXIS,
        ...this.#layout_general.yaxis,
      },
    };
    // fondo alternante
    const alternatingBackground = this.#alternating_background.isNeeded
      ? this.#createAlternatingBackground()
      : {};

    return {
      ...baseLayout,
      shapes: alternatingBackground,
      ...this.#layout_general.objLayout,
    };
  }

  get plotData() {
    //!Falta CASO de todo vacio allEmpty
    if (this.#allEmpty) {
      return EMPTY_PLOTDATA;
    }
    /*asignar configuracion general para cada linea
    modo
    type
    hovertemplate
    hoverlabel:{bgcolor,font{color}}
    hoverinfo
    line {shape,smoothing}
    marker:{size}
    */
    //cortar downtimes al margen de datos

    const downtimes = this.#downtime_mode ? this.#raw_downtimes ?? [] : [];
    const trips = this.#trip_mode ? this.#raw_trips ?? [] : [];
    const basePlot = {
      ...this.#getPlotdata_general(),
      ...this.#user_plotdata_obj,
    };
    //si hay mas de un slot, mostrar leyenda
    const isMoreThanOneSlot = this.#raw_data.length > 1;
    basePlot.showlegend = isMoreThanOneSlot;

    const traces = getBasePlotData(
      this.#raw_data,
      downtimes,
      basePlot,
      this.#alternating_traces_colors
    );
    //crear lineas de downtimes
    const YDELTA = this.#data_range.ymax - this.#data_range.ymin;
    const ymax = this.#data_range.ymax + YDELTA * this.#margin_decimal.top;
    const ymin = this.#data_range.ymin - YDELTA * this.#margin_decimal.bottom;
    const downtimeLines = getDowntimeLines(downtimes, ymin, ymax);
    const tripsLines = getTripsLines(trips, ymin, ymax);

    return [...traces, ...downtimeLines, ...tripsLines];
  }

  get plotConfig() {
    return {
      responsive: true,
      locale: this.#locale,
      modeBarButtonsToAdd: this.#buttons,
    };
  }

  addButton = (name, icon = icon1, direction = "up", callback) => {
    this.#buttons.push({
      name,
      icon,
      direction,
      click: callback,
    });
    return this;
  };

  // --- plotdata ---
  addDowntimes = (downtimes) => {
    this.#raw_downtimes = downtimes;
    return this;
  };

  addTripsEvents = (trips) => {
    this.#raw_trips = trips;
    return this;
  };

  downtimeMode = (mode) => {
    this.#downtime_mode = mode;
    return this;
  };

  tripMode = (mode) => {
    this.#trip_mode = mode;
    return this;
  };
}

//layout
const EMPTY_LAYOUT = {
  title: "No data",
  autosize: true,
  xaxis: {
    automargin: true,
    autorange: true,
    visible: false,
  },
  yaxis: {
    automargin: true,
    autorange: true,
    visible: false,
  },
};

/**
 * Given an number of hours, returns the plotly shapes for background
 * @param {*} interval Number of hours between each background, must be factor of 24 or multiple of 24
 * @param {*} initialDay first day of the background
 * @param {*} finalDay final day of the background
 * @return {Array} Array of dates with the background shapes
 */
function getDatesBetween(interval, initialDay, finalDay) {
  //check if initialDay and finalDay are dates
  if (!(initialDay instanceof Date) || !(finalDay instanceof Date)) {
    throw new Error("initialDay and finalDay must be dates");
  }
  //check if interval is factor of 24 or multiple of 24
  if (!(interval % 24 === 0 || 24 % interval === 0)) {
    throw new Error("interval must be factor of 24 or multiple of 24");
  }
  //check if initialDay is before finalDay
  if (initialDay > finalDay) {
    throw new Error("initialDay must be before finalDay");
  }

  let dates = [new Date(initialDay)];
  let currentDate = initialDay;

  //final del primer fondo alternante ¿mismo dia a las 12 o siguiente dia a las 00:00?
  let auxDay = new Date(currentDate);
  auxDay.setHours(auxDay.getHours() + interval);
  if (currentDate.getDay() === auxDay.getDay()) {
    currentDate.setHours(interval, 0, 0);
  } else {
    currentDate.setDate(currentDate.getDate() + 1);
    currentDate.setHours(0, 0, 0);
  }
  dates.push(new Date(currentDate));

  while (currentDate < finalDay) {
    currentDate.setHours(currentDate.getHours() + interval);
    dates.push(new Date(currentDate));
  }
  //Termina con el ultimo dia
  dates[dates.length - 1] = new Date(finalDay);
  return dates;
}

function createAlternatingBackground(dates, miny, maxy, fillcolor, opacity) {
  function backgroundShape(
    currentDate,
    nextDate,
    miny,
    maxy,
    fillcolor,
    opacity
  ) {
    return {
      fillcolor,
      line: {
        width: 0,
      },
      opacity,
      type: "rect",
      y0: miny,
      y1: maxy,
      xref: "x",
      x0: currentDate,
      x1: nextDate,
      yref: "y",
      z: -2000,
      layer: "below",
    };
  }
  let shapes = [];
  for (let index = 0; index <= dates.length; index = index + 2) {
    const currentDate = dates[index];
    const nextDate = dates[index + 1];
    nextDate
      ? shapes.push(
          backgroundShape(currentDate, nextDate, miny, maxy, fillcolor, opacity)
        )
      : null;
  }
  return shapes;
}

//plotdata
const EMPTY_PLOTDATA = [
  {
    mode: "lines+text",
    x: ["2021-12-02 09:18:00"],
    y: [0],
    text: ["No data"],
    textposition: "top center",
    textfont: {
      family: "Arial",
      size: 30,
      color: "rgba(0, 0, 0,0.5)",
    },
  },
];

function getBasePlotData(
  slotsData,
  downtimes = [],
  basePlot,
  alternantingColors
) {
  const base = basePlot;
  let traces = [];
  slotsData.forEach((slot, index) => {
    traces.push(
      ...separateSlot2Traces(slot, downtimes, base, alternantingColors[0])
    );
  });
  return traces;
}

function separateSlot2Traces(
  slotData,
  rawDowntimes,
  basePlot,
  alternantingColors
) {
  //points to plot
  const xData = slotData.x.map((x) => {
    return new Date(x);
  });
  const yData = slotData.y;
  //downtime
  const downtimes = rawDowntimes
    .map((downtime) => {
      return new Date(downtime);
    })
    .sort((a, b) => {
      return a - b;
    });
  let newPlotData = [];
  let firstItem_index = 0;
  let boolColorLine = false;

  const MAX_POINT_MARKERS = 500;
  const mode = xData.length < MAX_POINT_MARKERS ? "lines+markers" : "lines";
  basePlot.mode = mode;
  function newLine(x, y, name, color, basePlot) {
    const base = JSON.parse(JSON.stringify(basePlot));
    let line = {
      x,
      y,
      name,
      legendgroup: name,
      ...base,
    };
    line.line.color = color;
    return line;
  }
  downtimes.forEach((downtime) => {
    //encontrar donde ocurre el downtime en un array de fechas
    const lastItem_index = xData.findIndex((x) => {
      return x >= downtime;
    });

    if (!(lastItem_index < 0)) {
      //calcular x and y
      let line = newLine(
        xData.slice(firstItem_index, lastItem_index),
        yData.slice(firstItem_index, lastItem_index),
        slotData.name,
        boolColorLine ? alternantingColors[0] : alternantingColors[1],
        basePlot
      );
      boolColorLine = !boolColorLine;
      newPlotData.push(line);
      firstItem_index = lastItem_index;
    }
  });
  //last line
  newPlotData.push(
    newLine(
      xData.slice(firstItem_index),
      yData.slice(firstItem_index),
      slotData.name,
      boolColorLine ? alternantingColors[0] : alternantingColors[1],
      basePlot
    )
  );
  return newPlotData;
}

function getDowntimeLines(downtimes, miny, maxy) {
  function newDowntimeLine(x, y) {
    return {
      //mode: "lines+text",
      mode: "lines",
      showlegend: false,
      line: {
        width: 3,
        color: "rgba (250, 0, 0, 0.3)",
      },
      y,
      x,
      //text: ["DOWNTIME", "DOWNTIME"],
      hoveron: "points",
      hovertemplate: "Downtime<br><b> Fecha</b>: %{x}<br><extra></extra>",
      hoverlabel: {
        bgcolor: "DB504A",
        font: {
          color: "black",
        },
      },
    };
  }
  let shapes = [];
  downtimes.forEach((downtime) => {
    const startDate = new Date(downtime);
    let x = [];
    let y = [];
    const dataLenght = 10;
    for (let index = 0; index < dataLenght + 1; index++) {
      y.push(miny + (index * (maxy - miny)) / dataLenght);
      x.push(startDate);
    }
    shapes.push(newDowntimeLine(x, y));
  });
  return shapes;
}

function getTripsLines(trips, miny, maxy) {
  console.log("getTripsLines", trips);
  function newTripLine(x, y, type) {
    const color =
      type === "start" ? "rgba (0, 250, 0, 0.3)" : "rgba (0, 0, 250, 0.3)";
    return {
      //mode: "lines+text",
      mode: "lines",
      showlegend: false,
      line: {
        width: 3,
        color,
      },
      y,
      x,
      //text: ["DOWNTIME", "DOWNTIME"],
      hoveron: "points",
      hovertemplate: `${
        type == "start" ? "Inicio" : "Final"
      }<br><b> Fecha</b>: %{x}<br><extra></extra>`,
      hoverlabel: {
        bgcolor:
          type === "start" ? "rgba (0, 250, 0, 0.3)" : "rgba (0, 0, 250, 0.3)",
        font: {
          color: "black",
        },
      },
    };
  }
  let shapes = [];
  trips.forEach((trip) => {
    const startDate = new Date(trip.timestamp);
    let x = [];
    let y = [];
    const dataLenght = 10;
    for (let index = 0; index < dataLenght + 1; index++) {
      y.push(miny + (index * (maxy - miny)) / dataLenght);
      x.push(startDate);
    }
    shapes.push(newTripLine(x, y, trip.type));
  });
  return shapes;
}

const icon1 = {
  width: 500,
  height: 600,
  path: "M224 512c35.32 0 63.97-28.65 63.97-64H160.03c0 35.35 28.65 64 63.97 64zm215.39-149.71c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32 .05-7.55-2.61-15.27-8.61-21.71z",
};
